import React, {useEffect, useState} from 'react';
import './App.scss';
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Login from "./pages/logIn/Login";
import {useMetaMask} from "metamask-react";
import {Navigate, Route, Routes} from 'react-router-dom';
import Loader from "./components/loader/Loader";
import {Slide, toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  maxSupply,
  ownerMinted,
  ownerReserved,
  pb,
  pbBalance,
  pbMint,
  providerHandler,
  totalSupply,
  wl,
  wlBalance,
  wlMint
} from "./web3/contractInteraction";
import MintBox from "./components/common/MintBox";
import {getSignature} from "./utils/backendApi";
import configs from './utils/config.json';

const App = () => {
  const { status, connect, account, chainId } = useMetaMask();
  const [loading, setLoading] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [mintDetails, setMintDetails] = useState();
  const [pastMinted, setPastMinted] = useState(0);
  const [mintCount, setMintCount] = useState(1);
  const [totalNFTs, setTotalNFTs] = useState(0);
  const [saleSupply, setSaleSupply] = useState(0);
  const [totalMintedNFTs, setTotalMintedNFTs] = useState(0);
  const [isUserWhitelisted, setIsUserWhitelisted] = useState(true);
  const [mintMessage, setMintMessage] = useState(false);
  const [saleCondition, setSaleCondition] = useState();

  useEffect(() => {
    handleChainChange()
    // eslint-disable-next-line
  }, [chainId]);

  useEffect(() => {
    accountSetup()
    // eslint-disable-next-line
  }, [account]);

  useEffect(() => {
    getAllDetails()
    // eslint-disable-next-line
  }, [account, chainId, window.location.pathname]);

  useEffect(() => {
    setTotalPrice(Number(mintCount) * Number(mintDetails?.mintPrice))
  }, [mintCount, mintDetails?.mintPrice]);

  const accountSetup = async () => {
    await providerHandler()
  }

  const connectWallet = () => {
    connect()
  }

  const getAllDetails = async () => {
    setMintMessage(false)
    if (account !== null) {
      const currentTimeSeconds = parseInt(Date.now() / 1000);
      setLoading(true)
      let details;
      let alreadyMinted;
      if (window.location.pathname === '/public') {
        details = await pb()
        alreadyMinted = await pbBalance(account)
        setIsUserWhitelisted(true)
      } else {
        const listType = window.location.pathname === '/guaranteed' ? 1 : 2
        details = await wl(listType)
        alreadyMinted = await wlBalance(account, listType)

        const tuple = await getSignature(account, listType)
        if (tuple?.signature && tuple.listType == listType) {
          setIsUserWhitelisted(true)
        } else {
          setIsUserWhitelisted(false)
        }
      }
      setMintDetails(details)
      setPastMinted(alreadyMinted)

      let salestatus;
      if (details.startTime < currentTimeSeconds && details.endTime > currentTimeSeconds) {
        salestatus = "started"
      } else if (details.startTime > currentTimeSeconds) {
        salestatus = "notStarted"
      } else {
        salestatus = "saleOver"
      }
      setSaleCondition(salestatus);

      const ownerRemains = await ownerReserved()
      const ownerPastMinted = await ownerMinted()
      const total = await maxSupply()
      const totalMinted = await totalSupply()

      const supplyAfterReserved = (total - ownerRemains) - (totalMinted - ownerPastMinted);
      const salesSupply = Math.min(details.remainingTokens, supplyAfterReserved);
      setTotalMintedNFTs(totalMinted)
      setSaleSupply(salesSupply)
      setTotalNFTs(total)
      setMintCount(1)
      setLoading(false)
    }
  }

  const toastOptions = {
    autoClose: 3000,
    position: "bottom-left",
    transition: Slide,
    closeButton: false,
    hideProgressBar: true,
    closeOnClick: false,
    theme: "dark",
    icon: false,
    style: {
      textAlign: "center"
    }
  }

  const errorCatch = (e) => {
    const error = e?.toString().split('reason="')[1];
    const errorMessage = error?.toString().split('"')[0];
    toast.error(
      errorMessage === 'execution reverted: Pausable: paused'
        ? 'Mint is temporarily paused.'
        : e.code === "INSUFFICIENT_FUNDS"
        ? "Insufficient balance."
        : e.code === 'ACTION_REJECTED'
          ? 'User rejected transaction!'
          : e.code === "UNPREDICTABLE_GAS_LIMIT"
            ? "Something went wrong."
            : "Transaction unsuccessful.",
      toastOptions
    );
    setLoading(false)
  }

  const handleWlMint = async () => {
    setLoading(true)
    if (window.location.pathname === '/public') {
      try {
        await pbMint(totalPrice, account, mintCount)
        toast.success('Transaction successful.', toastOptions)
        await getAllDetails()
        setMintMessage(true)
      } catch (e) {
        errorCatch(e)
      }
    } else {
      const listType = window.location.pathname === '/guaranteed' ? 1 : 2
      const tuple = await getSignature(account, listType)
      try {
        await wlMint(account, totalPrice, tuple.signature, mintCount, listType)
        toast.success('Transaction successful.', toastOptions)
        await getAllDetails()
        setMintMessage(true)
      } catch (e) {
        errorCatch(e)
      }
    }
  }

  const handleChainChange = async () => {
    const chainID = configs.CHAIN_ID;
    if (chainId !== null && chainId !== `0x${chainID}`) {
      setLoading(true)
      toast.warning("Switch to Ethereum Mainnet", { ...toastOptions, autoClose: false, toastId: chainID, });
      await window.ethereum
        .request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: `0x${chainID}` }],
        })
        .then(() => {
          toast.dismiss(chainID);
          setLoading(false)
        })
    } else {
      toast.dismiss(chainID);
    }
  }

  return (
    <>
      <ToastContainer/>
      <Loader loading={loading}/>
      <Header account={account} connectWallet={connectWallet} status={status}/>
      {
        status === 'initializing' ?
          <Loader loading={true}/> :
          status === 'connected' ?
            <Routes>
              <Route path='/*' element={<Navigate to='/guaranteed'/>}/>
              {["/public", "/guaranteed", "/allowlist"].map((path, index) =>
                <Route path={path}
                       element={<MintBox mintDetails={mintDetails}
                                         mintCount={mintCount}
                                         setMintCount={setMintCount}
                                         getAllDetails={getAllDetails}
                                         saleCondition={saleCondition}
                                         handleWlMint={handleWlMint}
                                         totalPrice={totalPrice}
                                         totalNFTs={totalNFTs}
                                         saleSupply={saleSupply}
                                         totalMintedNFTs={totalMintedNFTs}
                                         isUserWhitelisted={isUserWhitelisted}
                                         mintMessage={mintMessage}
                                         pastMinted={pastMinted}/>}
                       key={index}
                />
              )}
            </Routes>
            :
            <Login connectWallet={connectWallet} status={status}/>
      }
      <Footer/>
    </>
  );
}

export default App;
