import React from 'react';
import './footer.scss';

const Footer = () => {

  return (
    <>
      <div className='footer_main_div'>
        <div className='middle_text_div'>
          <div className='footer_text'>
            <p className='copy_right'>© Supermeta. All rights reserved.</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;