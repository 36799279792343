import React from 'react';
import './header.scss';
import logo from '../../assets/whiteLogo.png';

const Header = ({ account, status, connectWallet }) => {

  return (
    <>
      <div className='main_header_div'>
        <div className='inner_head_div'>
          <div className='heading'><img src={logo} alt='logo'/></div>
          <button className='sign_in_btn'
                  onClick={status === "notConnected" ? connectWallet : null}>
            {status === "notConnected" ? 'Connect' : status === "connecting" || status === "initializing" ? 'initializing...' : `${account?.slice(0, 5)}...${account?.slice(-5)}`}</button>

        </div>
      </div>
    </>
  );
}

export default Header;