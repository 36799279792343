import {ethers} from "ethers";
import config from "../utils/config.json";
import abi from "./abi.json";
import drop04Abi from "./drop04Abi.json";

let contract;
let drop04Contract;
const contractAddress = config.contractAddress;

export const providerHandler = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const account = await provider.listAccounts();
  const address = account[0];
  const signer = provider.getSigner();
  contract = new ethers.Contract(contractAddress, abi, signer);
  drop04Contract = new ethers.Contract(config.dro04Address, drop04Abi, signer);
  return address;
};

export const wl = async (wlType) => {
  const func = `WL${wlType}`;
  const n = await contract[func]();
  return {
    startTime: n.startTime.toNumber(),
    endTime: n.endTime.toNumber(),
    buyLimitPerWallet: n.buyLimit.toNumber(),
    remainingTokens: n.maxAvailable.toNumber(),
    mintPrice: ethers.utils.formatEther(n.price.toString())
  };
}

export const pb = async () => {
  const n = await contract.PB();
  return {
    startTime: n.startTime.toNumber(),
    endTime: n.endTime.toNumber(),
    buyLimitPerWallet: n.buyLimit.toNumber(),
    remainingTokens: n.maxAvailable.toNumber(),
    mintPrice: ethers.utils.formatEther(n.price.toString())
  };
}

export const maxSupply = async () => {
  const n = await contract.maxSupply();
  return n.toNumber()
}

export const wlBalance = async (account, wlType) => {
   const func = `readWL${wlType}register`;
  const n = await contract[func](account);
  return n.toNumber()
}

export const pbBalance = async (account) => {
  const n = await contract.readPBregister(account);
  return n.toNumber()
}

export const totalSupply = async () => {
  const n = await drop04Contract.totalSupply();
  return n.toNumber()
}

export const ownerReserved = async () => {
  const n = await contract.ownerReserve();
  return n.toNumber()
}

export const ownerMinted = async () => {
  const n = await contract.ownerMinted();
  return n.toNumber()
}

export const wlMint = async (account, price, signatureTuple, amount, wlType) => {
  const func = `WL${wlType}mint`;
  const n = await contract[func](account, signatureTuple, amount, { value: ethers.utils.parseEther(price.toString()) });
  await n.wait();
  return n
}

export const pbMint = async (price, account, amount) => {
  const n = await contract.publicMint(account, amount, { value: ethers.utils.parseEther(price.toString()) });
  await n.wait();
  return n
}