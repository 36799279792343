import React, {useEffect} from 'react';
import './mintBox.scss';
import {useNavigate} from "react-router-dom";
import logo from '../../assets/blackLogo.png';

const MintBox = ({
                   totalNFTs,
                   mintDetails,
                   mintCount,
                   getAllDetails,
                   setMintCount,
                   saleCondition,
                   totalPrice,
                   saleSupply,
                   totalMintedNFTs,
                   isUserWhitelisted,
                   handleWlMint,
                   mintMessage,
                   pastMinted
                 }) => {
  const pathName = window.location.pathname
  const navigate = useNavigate()
  const percentage = totalMintedNFTs / totalNFTs * 100

  useEffect(() => {
    getAllDetails()
    // eslint-disable-next-line
  }, [window.location.pathname]);


  const decreaseMint = () => {
    if (mintCount > 1) {
      setMintCount(mintCount - 1)
    }
  }

  const increaseMint = () => {
    if (mintCount < Math.min(mintDetails?.buyLimitPerWallet - pastMinted, saleSupply)) {
      setMintCount(mintCount + 1)
    }
  }

  return (
    <>
      <div className='center_div'>
        <div className='middle_div'>
          <div className="text_move">
            <div className="marquee">
              <p>&nbsp;supermeta // supermeta // supermeta // supermeta // supermeta // supermeta // supermeta // </p>
              <p>&nbsp;supermeta // supermeta // supermeta // supermeta // supermeta // supermeta // supermeta // </p>
            </div>
          </div>
          <div className='progress'>
            <div className='progress-bar' style={{ width: percentage + "%" }}></div>
            <p className='absolute_text'>{totalMintedNFTs} / {totalNFTs}</p>
          </div>
          <div className='nav_div'>
            <button onClick={() => navigate('/guaranteed')}
                    className={`navLink ${pathName === '/guaranteed' && 'active'}`}>
              <li>Guaranteed</li>
            </button>
            <button onClick={() => navigate('/allowlist')}
                    className={`navLink ${pathName === '/allowlist' && 'active'}`}>
              <li>Allowlist</li>
            </button>
            <button onClick={() => navigate('/public')} className={`navLink ${pathName === '/public' && 'active'}`}>
              <li>Public</li>
            </button>

          </div>
          <div className='main_box_div'>
            <div className="wrapper Right">
              <div className="marquee">
                <p>&nbsp;supermeta // supermeta // supermeta // supermeta // supermeta // supermeta // supermeta // </p>
                <p>&nbsp;supermeta // supermeta // supermeta // supermeta // supermeta // supermeta // supermeta // </p>
              </div>
            </div>
            <div className="wrapper Left">
              <div className="marquee">
                <p>&nbsp;supermeta // supermeta // supermeta // supermeta // supermeta // supermeta // supermeta // </p>
                <p>&nbsp;supermeta // supermeta // supermeta // supermeta // supermeta // supermeta // supermeta // </p>
              </div>
            </div>
            <div className='left_div'>
              <h1 className='box_heading'><img src={logo} alt='logo'/></h1>
              <p className='text_desc'>A brand new PHYGITAL experience where YOU become the artist of your NFT.</p>
              <p className='text_desc'>Proudly supported by UNIQLO.</p>
            </div>
            <div className='right_box_div'>
              <div className='mint_box_middle_div'>
                <div className='mind_details_div'>
                  <p className='first_column'>Price per token</p>
                  <p className='second_column'>{mintDetails?.mintPrice} ETH</p>
                </div>
                <div className='mind_details_div'>
                  <p className='first_column'>Mint limit per wallet</p>
                  <p className='second_column'>{mintDetails?.buyLimitPerWallet} </p>
                </div>
                <div className='mind_details_div'>
                  <p className='first_column'>You have minted</p>
                  <p className='second_column'>{pastMinted}/{mintDetails?.buyLimitPerWallet} </p>
                </div>
                <div className='counter_div'>
                  <div className='plus_minus_btn' onClick={decreaseMint}>-</div>
                  <div className='center_count'>{mintCount}</div>
                  <div className='plus_minus_btn' onClick={increaseMint}>+</div>
                </div>
                <div className='mind_details_div total_div'>
                  <p className='first_column'>Total Price</p>
                  <p className='second_column'>{totalPrice} ETH </p>
                </div>
              </div>
              <div className='mint_btn_div'>
                {isUserWhitelisted ?
                  saleCondition === 'notStarted' ?
                    <p>{`${pathName === '/public' ? "" : 'You’re on the list! '}Sale will begin soon.`}</p> :
                    saleCondition === 'saleOver' ?
                      <p>Sorry, sale is over.</p> :
                      saleSupply === 0 ?
                        <p>Sold out!</p> :
                        pastMinted >= mintDetails?.buyLimitPerWallet || mintMessage ?
                          <>
                            <p>Thanks for minting [ AI + U ] ! <br/>
                              You can visit this <a
                                href='https://bueno.art/e7bl38zdmhungrhygkcwe/aiu' target='_blank'>link</a> to create
                              your AI Artwork.</p>
                            <button className='link_btn'><a
                              href='https://bueno.art/e7bl38zdmhungrhygkcwe/aiu' target='_blank'>Create AI Artwork</a>
                            </button>
                          </>
                          :
                          <button className='mint_btn' onClick={handleWlMint}>Mint</button> :
                  <p>Sorry, you’re not on this list.</p>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MintBox;