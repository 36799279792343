import configs from './config.json';
import axios from "axios";

export const getSignature = async (address, listType) => {
  try {
    const { data } = await axios.post(configs.BASE_URL + 'signer', { address: address, listType: listType });
    return data;
  } catch (error) {
    console.error("Backend-error: " + error);
    return false;
  }
};