import React from 'react';
import './login.scss';
import Loader from "../../components/loader/Loader";
import logo from '../../assets/blackLogo.png';

const Login = ({ status, connectWallet }) => {

  return (
    <>
      {status === "connecting" || status === "initializing" ? <Loader loading={true}/> : null}
      <div className='login_main_div'>
        <div className='middle_box_div'>
          <div className="wrapper connect Right">
            <div className="marquee">
              <p>&nbsp;supermeta // supermeta // supermeta // supermeta // supermeta // supermeta // supermeta // </p>
              <p>&nbsp;supermeta // supermeta // supermeta // supermeta // supermeta // supermeta // supermeta // </p>
            </div>
          </div>
          <div className="wrapper connect Left">
            <div className="marquee">
              <p>&nbsp;supermeta // supermeta // supermeta // supermeta // supermeta // supermeta // supermeta // </p>
              <p>&nbsp;supermeta // supermeta // supermeta // supermeta // supermeta // supermeta // supermeta // </p>
            </div>
          </div>
          <h1 className='box_heading'><img src={logo} alt='logo'/></h1>
          <p className='box_text'>A brand new PHYGITAL experience where YOU become the artist of your NFT.<br/>  Proudly supported by UNIQLO.</p>
          <div className='box_btn_div'>
            <button onClick={status === "notConnected" ? connectWallet : null} className='box_btn'>
              {status === "connecting" || status === "initializing" ? <>Initializing...</> : null}
              {status === "unavailable" ? <>Install MetaMask</> : null}
              {status === "notConnected" ? <>Connect</> : null}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;